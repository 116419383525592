<!--
  -- Created by zed on 2022/3/1
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 宠物数据
-->
<template>
  <div class='dashboard__wrap'>
    <!-- 宠物性别（猫） -->
    <el-row justify="center">
      <!-- <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>宠物性别比例分析（猫）</div>
          <pie-chart
            ref='catSexPieChart'
            width='100%'
            height='400px'
            tooltip='宠物数量'
            @onPieceClick='onPetVarietyClick'
          />
        </div>
      </el-col> -->
      <el-col>
        <div class="chart-wrapper">
          <div class='chart-title'>宠物性别数据统计（猫）</div>
          <bar-chart
            ref='catSexBarChart'
            direction='vertical'
            width='100%'
            height='400px'
            tooltip='宠物数量'
            @onAxisClick='onPetVarietyClick'
          />
        </div>
      </el-col>
    </el-row>
    <!-- 宠物性别（狗） -->
    <el-row justify="center">
      <!-- <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>宠物性别比例分析（狗）</div>
          <pie-chart
            ref='dogSexPieChart'
            width='100%'
            height='400px'
            tooltip='宠物数量'
            @onPieceClick='onPetVarietyClick'
          />
        </div>
      </el-col> -->
      <el-col>
        <div class="chart-wrapper">
          <div class='chart-title'>宠物性别数据统计（狗）</div>
          <bar-chart
            ref='dogSexBarChart'
            direction='vertical'
            width='100%'
            height='400px'
            tooltip='宠物数量'
            @onAxisClick='onPetVarietyClick'
          />
        </div>
      </el-col>
    </el-row>
    <!-- 宠物品种（猫） -->
    <el-row justify="center">
      <!-- <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>宠物品种比例分析（猫）</div>
          <pie-chart
            ref='catVarietyPieChart'
            width='100%'
            :height='catVarietyPieChartHeight'
            tooltip='宠物数量'
            @onPieceClick='onPetVarietyClick'
          />
        </div>
      </el-col> -->
      <el-col>
        <div class="chart-wrapper">
          <div class='chart-title'>宠物品种数据统计（猫）</div>
          <bar-chart
            ref='catVarietyBarChart'
            direction='horizontal'
            width='100%'
            height='auto'
            tooltip='宠物数量'
            @onAxisClick='onPetVarietyClick'
          />
        </div>
      </el-col>
    </el-row>
    <!-- 宠物品种（狗） -->
    <el-row justify="center">
      <!-- <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>宠物品种比例分析（狗）</div>
          <pie-chart
            ref='dogVarietyPieChart'
            width='100%'
            :height='dogVarietyPieChartHeight'
            tooltip='宠物数量'
            @onPieceClick='onPetVarietyClick'
          />
        </div>
      </el-col> -->
      <el-col>
        <div class="chart-wrapper">
          <div class='chart-title'>宠物品种数据统计（狗）</div>
          <bar-chart
            ref='dogVarietyBarChart'
            direction='horizontal'
            width='100%'
            height='auto'
            tooltip='宠物数量'
            @onAxisClick='onPetVarietyClick'
          />
        </div>
      </el-col>
    </el-row>
    <!-- 宠物粮食（猫） -->
    <el-row justify="center">
      <!-- <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>宠物粮食比例分析（猫）</div>
          <pie-chart
            ref='catFoodPieChart'
            width='100%'
            :height='catFoodPieChartHeight'
            tooltip='食物数量'
          />
        </div>
      </el-col> -->
      <el-col style='height: auto'>
        <div class="chart-wrapper">
          <div class='chart-title'>宠物粮食数据统计（猫）</div>
          <bar-chart
            ref='catFoodBarChart'
            direction='horizontal'
            width='100%'
            height='auto'
            tooltip='食物数量'
          />
        </div>
      </el-col>
    </el-row>
    <!-- 宠物粮食（狗） -->
    <el-row justify="center">
      <!-- <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>宠物粮食比例分析（狗）</div>
          <pie-chart
            ref='dogFoodPieChart'
            width='100%'
            :height='dogFoodPieChartHeight'
            tooltip='食物数量'
          />
        </div>
      </el-col> -->
      <el-col style='height: auto'>
        <div class="chart-wrapper">
          <div class='chart-title'>宠物粮食数据统计（狗）</div>
          <bar-chart
            ref='dogFoodBarChart'
            direction='horizontal'
            width='100%'
            height='auto'
            tooltip='食物数量'
          />
        </div>
      </el-col>
    </el-row>
    <!-- 宠物感情经历（猫） -->
    <el-row justify="center">
      <!-- <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>宠物感情经历比例分析（猫）</div>
          <pie-chart
            ref='catEmotionPieChart'
            width='100%'
            height='400px'
            tooltip='宠物数量'
          />
        </div>
      </el-col> -->
      <el-col>
        <div class="chart-wrapper">
          <div class='chart-title'>宠物感情经历数据统计（猫）</div>
          <bar-chart
            ref='catEmotionBarChart'
            direction='vertical'
            width='100%'
            height='400px'
            tooltip='宠物数量'
          />
        </div>
      </el-col>
    </el-row>
    <!-- 宠物感情经历（狗） -->
    <el-row justify="center">
      <!-- <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>宠物感情经历比例分析（狗）</div>
          <pie-chart
            ref='dogEmotionPieChart'
            width='100%'
            height='400px'
            tooltip='宠物数量'
          />
        </div>
      </el-col> -->
      <el-col>
        <div class="chart-wrapper">
          <div class='chart-title'>宠物感情经历数据统计（狗）</div>
          <bar-chart
            ref='dogEmotionBarChart'
            direction='vertical'
            width='100%'
            height='400px'
            tooltip='宠物数量'
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import BarChart from './components/BarChart';

const CAT = 0
const DOG = 1

export default {
  name: 'PetBoard',
  components: { BarChart },
  data() {
    return {
      catVarietyPieChartHeight: 0,
      dogVarietyPieChartHeight: 0,
      catFoodPieChartHeight: 0,
      dogFoodPieChartHeight: 0,
    }
  },
  mounted() {
    this.setupPetSex(CAT)
    this.setupPetSex(DOG)
    this.setupPetVariety(CAT)
    this.setupPetVariety(DOG)
    this.setupPetFood(CAT)
    this.setupPetFood(DOG)
    this.setupPetEmotion(CAT)
    this.setupPetEmotion(DOG)
  },
  methods: {
    /** 宠物性别数据统计 */
    async setupPetSex(type) {
      let resp = await this.$moe_api.STATISTICS_API.readPetSex(type)
      if (resp.code === 200) {
        let dataSources = resp.result.map(item => {
          return {
            name: item.petSexDescription,
            value: item.total
          }
        })
        if (type === CAT) {
          // this.$refs.catSexPieChart.configChart(dataSources)
          this.$refs.catSexBarChart.configChart(dataSources)
        } else if (type === DOG) {
          // this.$refs.dogSexPieChart.configChart(dataSources)
          this.$refs.dogSexBarChart.configChart(dataSources)
        }
      }
    },
    /** 宠物品种数据统计 */
    async setupPetVariety(type) {
      let resp = await this.$moe_api.STATISTICS_API.readPetVariety(type)
      if (resp.code === 200) {
        let dataSources = resp.result.map(item => {
          return {
            id: item.petTypeId,
            name: item.varieties || '未填写',
            value: item.total
          }
        })
        if (type === CAT) {
          this.$refs.catVarietyBarChart.configChart(dataSources.reverse())
          this.catVarietyPieChartHeight = this.$refs.catVarietyBarChart.getChartSize().height
          // this.$refs.catVarietyPieChart.configChart(dataSources)
        } else if (type === DOG) {
          this.$refs.dogVarietyBarChart.configChart(dataSources.reverse())
          this.dogVarietyPieChartHeight = this.$refs.dogVarietyBarChart.getChartSize().height
          // this.$refs.dogVarietyPieChart.configChart(dataSources)
        }
      }
    },
    async setupPetFood(type) {
      let resp = await this.$moe_api.STATISTICS_API.readPetFood(type)
      if (resp.code === 200) {
        let dataSources = resp.result.map(item => {
          return {
            name: `${item.brandName || '未填写'}-${item.foodName || ''}`,
            value: item.total
          }
        })
        if (type === CAT) {
          this.$refs.catFoodBarChart.configChart(dataSources.reverse())
          // this.catFoodPieChartHeight = this.$refs.catFoodBarChart.getChartSize().height
          // this.$refs.catFoodPieChart.configChart(dataSources)
        } else if (type === DOG) {
          this.$refs.dogFoodBarChart.configChart(dataSources.reverse())
          // this.dogFoodPieChartHeight = this.$refs.dogFoodBarChart.getChartSize().height
          // this.$refs.dogFoodPieChart.configChart(dataSources)
        }
      }
    },
    async setupPetEmotion(type) {
      let resp = await this.$moe_api.STATISTICS_API.readPetEmotion(type)
      if (resp.code === 200) {
        let dataSources = resp.result.map(item => {
          return {
            name: item.emotionDescription,
            value: item.total
          }
        })
        if (type === 0) {
          // this.$refs.catEmotionPieChart.configChart(dataSources)
          this.$refs.catEmotionBarChart.configChart(dataSources)
        } else if (type === 1) {
          // this.$refs.dogEmotionPieChart.configChart(dataSources)
          this.$refs.dogEmotionBarChart.configChart(dataSources)
        }
      }
    },
    onPetVarietyClick() {}
  }
}
</script>

<style lang='scss' scoped>
.dashboard__wrap {
  padding:18px 16px;
}
.chart-title {
  text-align: center;
  margin-bottom: 16px;
}
.chart-subtitle {
  text-align: center;
  font-size: 14px;
  color: #999999;
  margin-top: 2px;
}
.chart-wrapper {
  font-size: 18px;
  text-align: center;
  background: white;
  padding: 16px;
  margin-bottom: 32px;
}
</style>
